import logoivaras from '../../../img/logo/ivaras-logo2.png';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NavBarStaff from '../../../component/navbar-staff';

function LandingDerecho(){
    return(
        <div>
            <NavBarStaff />
            <main class="main">
                <div class="formulario">
                    <form name="formulario" method="POST">
                        <h2>IMAGENES PANEL DERECHO</h2>
                        <div class="form-group subirimg">
                        <section class="upload-section">
                            <div class="mb-3">
                            <label for="formFile" class="form-label texto-subir-img">Subir imagen 1</label>
                            <input class="form-control" type="file" id="formFile"></input>
                            </div>
                        </section>
                        </div>
                        <div class="formulario">
                        <div class="d-grid gap-2 d-md-block">
                            <button type="button" class="btn btn-primary btn-lg boton">Subir</button>
                        </div>
                        </div>
                    </form>
                    </div>
                </main>
        </div>
    );
}
export default LandingDerecho;