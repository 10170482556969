import React, { useState } from 'react';
import axios from 'axios';
import NavBarStaff from '../../../component/navbar-staff';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);
function LandingInferior() {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('imagen', file);
    formData.append('descripcion', 'Lan_Inferior');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/landing/inferior/upload/`, formData);
      console.log(response.data);
      MySwal.fire({
        title: '¡Enviado!',
        text: 'Se ha enviado la imagen correctamente.',
        icon: 'success'
    });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <NavBarStaff />
      <main className="main">
        <div className="formulario">
          <form onSubmit={handleSubmit}>
            <h2>IMAGEN PANEL INFERIOR</h2>
            <div className="form-group subirimg">
              <section className="upload-section">
                <div className="mb-3">
                  <label htmlFor="formFile" className="form-label texto-subir-img">Subir imagen inferior</label>
                  <input className="form-control" type="file" id="formFile" onChange={handleFileChange}></input>
                  <label for="exampleFormControlInput1" class="form-label">Solo se aceptará formato de imagen jpg y png</label>
                  <button type="submit" className="btn btn-primary btn-lg boton">Subir</button>
                </div>
              </section>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
}

export default LandingInferior;
