import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import logoivaras from '../img/logo/ivaras-logo2.png';
import '../component/navbar-staff.css'
import { Link } from 'react-router-dom';

function Navbartaff(){
    useEffect(() =>{
        const menu = document.querySelector(".menu-content");
        const menuItems = document.querySelectorAll(".submenu-item");
        const subMenuTitles = document.querySelectorAll(".submenu .menu-title");

        menuItems.forEach((item, index) => {
          item.addEventListener("click", () => {
            menu.classList.add("submenu-active");
            item.classList.add("show-submenu");
            menuItems.forEach((item2, index2) => {
              if (index !== index2) {
                item2.classList.remove("show-submenu");
              }
            });
          });
        });
        
        subMenuTitles.forEach((title) => {
          title.addEventListener("click", () => {
            menu.classList.remove("submenu-active");
          });
        });
    }, []);
    return(
        <div>
            <nav className="sidebar">
                <Link to={'/staff/index'}><img src={logoivaras} alt="" className="logo"></img></Link>

                <div className="menu-content">
                    <ul className="menu-items">

                    <li className="item">
                        <div className="submenu-item">
                        <span>LANDING</span>
                        <i className="fa-solid fa-chevron-right"></i>
                        </div>

                        <ul className="menu-items submenu">
                        <div className="menu-title">
                            <i className="fa-solid fa-chevron-left"></i>
                            LANDING
                        </div>
                        <li className="item">
                            <Link to={'/staff/entornos'}>Entornos</Link>
                        </li>
                        <li className="item">
                            <Link to={'/staff/landing/panel/derecho'}>Panel derecho</Link>
                        </li>
                        <li className="item">
                            <Link to={'/staff/landing/panel/inferior'}>Panel inferior</Link>
                        </li>
                        <li className="item">
                            <Link to={'/staff/landing/panel/izquierdo'}>Talleres</Link>
                        </li>
                        <li className="item">
                            <Link to={'/staff/redes'}>Redes</Link>
                        </li>
                        <li className="item">
                            <Link to={'/staff/noticias'}>Noticias</Link>
                        </li>
                        </ul>
                    </li>

                    <li className="item">
                        <div className="submenu-item">
                        <span>VISTA DETALLE</span>
                        <i className="fa-solid fa-chevron-right"></i>
                        </div>

                        <ul className="menu-items submenu">
                        <div className="menu-title">
                            <i className="fa-solid fa-chevron-left"> VISTA DETALLE</i>
                            
                        </div>
                        <li className="item">
                            <Link to={'/staff/entorno/Proyectos'}>Proyectos</Link>
                        </li>
                        <li className="item">
                            <Link to={'/staff/entorno/Eventos'}>Eventos</Link>
                        </li>
                        </ul>
                    </li>

                    <li className="item">
                        <a href="#">.</a>
                    </li>

                    <li className="item">
                        <a className="itemred" href="login.html">Cerrar Sesión</a>
                    </li>

                    </ul>
                </div>
            </nav>
        </div>
    );
}
export default Navbartaff;