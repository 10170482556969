import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import YouTube from 'react-youtube';
import { Link } from 'react-router-dom';
import './Detalle.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import { Form, useLocation } from 'react-router-dom';
import { Autoplay, Pagination, Navigation, EffectCoverflow } from 'swiper/modules';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import PreLoader from '../component/Preloader';
import { useParams } from 'react-router-dom';
import img_ivaras from '../img/detalle/Logo_ivaras_Blanco.png';
import img_duoc from '../img/detalle/duocblanco.png'
import axios from 'axios';


function DetalleiVaras() {
    const location = useLocation();
    const [bodyClass, setBodyClass] = useState('body-detalle-ivaras');
    const [filter, setFilter] = useState('');

    const { url_entorno } = useParams();
    const [imageLocations, setImageLocations] = useState([]);
    const [datos, setDatos] = useState(null);
    const [nombre_ambiente, setNombreAmbiente] = useState(''); // Añade esta línea
    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/img_ivaras/ivarasimg/?url_ambiente=${url_entorno}`)
        .then(response => response.json())
        .then(data => {
          const locations = data['Lista de Imagenes'].map(item => item['ubicacion1']);
          setImageLocations(locations);
        });
    }, []);
    useEffect(() => {
      if (location.pathname === `/detalle/${url_entorno}`) {
        document.body.className='body-detalle-ivaras';
        // Aquí puedes hacer algo adicional después de cargar el CSS
        console.log('CSS cargado');
      }
    }, [location, url_entorno]);
//Función acordeon

// Este es el componente del acordeón
const Accordion = ({ title, children, defaultOpen = false }) => {
  // Este es el estado que controla si el contenido está expandido o no
  const [isExpanded, setIsExpanded] = useState(defaultOpen);

  // Esta es la función que cambia el estado al hacer clic en el título
  const toggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="accordion">
      <div className="accordion-title" onClick={toggle}>
        {title}
      </div>
      <div
        className={`accordion-content ${isExpanded ? "expanded" : "collapsed"}`}
        aria-expanded={isExpanded}
      >
        {children}
      </div>
    </div>
  );
};


//Función video youtube
const videoUrl = 'https://www.youtube.com/watch?v=Pv-brjy76X0&t=4s';

// Extraer el ID del video directamente en el mismo lugar
const videoId = videoUrl.match(/[?&]v=([^&]+)/)?.[1] || null

const opts = {
  height: '390',
  width: '460',
  playerVars: {
    // Puedes configurar parámetros adicionales del reproductor aquí
    // Por ejemplo, autoplay: 1 para reproducir automáticamente el video
    autoplay: 1,
    mute: 1,
    rel: 0,
  },
};


const onReady = (event) => {
  // Acciones que deseas realizar cuando el reproductor esté listo
  //event.target.pauseVideo(); // Por ejemplo, pausar el video al inicio
};

  const [data, setData] = useState(null);

  const [selectedFile, setSelectedFile] = useState();
  const [selectedId, setSelectedId] = useState(1); // Añade esta línea
  const [titulo, setTitulo] = useState(''); // Añade esta línea
  const [descripcion, setDescripcion] = useState(''); // Añade esta línea
  const [objetivo, setObjetivo] = useState(''); // Añade esta línea
  const [mision_vision, setMision_vision] = useState(''); // Añade esta línea
  const [quienes_somos, setQuienes_somos] = useState(''); // Añade esta línea

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
};


    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/detalle/ivaras/texto/?url_ambiente=${url_entorno}`)
        .then(response => response.json())
        .then(data => {
          setDatos(data['Lista de Texto del Ambiente de iVaras'][0]);
          setTitulo(data['Lista de Texto del Ambiente de iVaras'][0].titulo);
          setDescripcion(data['Lista de Texto del Ambiente de iVaras'][0].descripcion);
          setObjetivo(data['Lista de Texto del Ambiente de iVaras'][0].objetivos);
          setMision_vision(data['Lista de Texto del Ambiente de iVaras'][0].mision_vision);
          setQuienes_somos(data['Lista de Texto del Ambiente de iVaras'][0].quienes_somos);
          console.log(titulo);
        });

    }, [url_entorno]); 

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/proyectos/filter/?ambiente_proyecto=${url_entorno}`)
        .then(response => response.json())
        .then(data => {
          setData(data);
          console.log(data);
        });
      }, []);


      useEffect(() => {
        if (filter === '1') { // Si el filtro es 'A-z'
            const sortedData = [...data].sort((a, b) => a.nombre_proyecto.localeCompare(b.nombre_proyecto));
            setData(sortedData);
        }
    }, [filter]);
    useEffect(() => {
      if (filter === '3') { // Si el filtro es 'Fecha Creacion'
          const sortedData = [...data].sort((a, b) => new Date(b.fecha_creacion) - new Date(a.fecha_creacion));
          setData(sortedData);
      }
  }, [filter]);
  useEffect(() => {
    if (location.pathname === `/detalle/${url_entorno}`) {
      document.body.className='body-detalle-ivaras';
      // Aquí puedes hacer algo adicional después de cargar el CSS
      console.log('CSS cargado');
    }
  }, [location, url_entorno]);



/*
if (!videoId){
  return <div>Error: No se puede extraer la ID del video</div>;
}

const embedUrl = `https://www.youtube.com/embed/${videoId}`;*/

    return (
            <div>
              <html lang="en">
              <head>
              
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link
                  rel="preconnect"
                  href="https://fonts.googleapis.com"
                />
                <link
                  rel="preconnect"
                  href="https://fonts.gstatic.com"
                  crossOrigin="true"
                />
                <link
                  href="https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap"
                  rel="stylesheet"
                />
                <title>Detalle - {titulo}</title>
              </head> 
              <>

              </>
            </html>
            <div class="contenedores">
                <div class="contenedor-izquierdo">
                <div class="caja-texto-contenedor">
                          <div className='superior-detalle'>
                          <Link to='../' className='volver'>
                            <img className='imglogo' src={img_ivaras}/>
                          </Link>
                          <img className='duoc' src={img_duoc}/>
                          </div>
                            <p className='titulo'>{titulo}</p>
                            <p className='content-text2'>{descripcion}</p>
                            <div class="contenedor-informacion">
                            <div class="hover-effect">                                    
                            <div class="content2">
                              {imageLocations.map((location, index, url_entorno,) => (
                                <img key={url_entorno} class={`img1`} src={`${process.env.REACT_APP_API_BASE_URL}/${location}`} alt={`Imagen ${url_entorno == url_entorno}`} />
                              ))}
                            </div>
                            </div>
                            </div>
     
                    </div>

                    <div class="caja-proyectos">
                      <p class="titulo2">Entorno {titulo}</p>
                        <div class="accordion">
                            <div class="accordion-item">
                                <Accordion title="Objetivos" defaultOpen={true}>
                                    <p  className='content-text'>{objetivo}</p>
                                </Accordion>
                            </div>
                            <div className='accordion-item'>
                                <Accordion title="Misión y Visión">
                                <p  className='content-text'>{mision_vision}</p>
                                </Accordion>
                            </div>
                            <div className='accordion-item'>
                                <Accordion title="Quienes somos">
                                <p  className='content-text'>{quienes_somos}</p>
                                </Accordion>
                            </div>


                            
                        </div>
                    </div>
                
                </div>
                <div class="contenedor-derecho">
                  
                <div className='contenedor-video'>
                  {videoId && <YouTube videoId={videoId} opts={opts} onReady={onReady} className='video'/>}
                </div>
               
                <div className='contenedor-proyectos'>
                <p class="titulo2">PROYECTOS</p>
                <select class="filtro" aria-label='Default select example' onChange={handleFilterChange}>
                  <option selected>Filtrar</option>
                  <option value={"1"}>A-z</option>
                  <option value={"2"}>Relevancia</option>
                  <option value={"3"}>Fecha Creacion</option>
                </select>
               
                {data && data.map(item => (           
                <div key={item.id} class="image-column">
                  <div class="image-column-item">
                  <Link className='hrefproyecto' to={`/detalle/${url_entorno}/${item.url_name}`}>
                        <div className="imagen-container-detalle">
                          <img className='imgicon' src={`${process.env.REACT_APP_API_BASE_URL}/${item.icono_proyecto}`} alt=""/>
                        </div>
                        <div class="image-caption">
                          <p class="caption-text">{item.nombre_proyecto}</p>
                        </div>
                  </Link>
                  </div>
                </div>
                ))}

             
                </div>
            </div>

        </div>
        </div>
      );
    }

export default DetalleiVaras;