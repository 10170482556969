import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import LogoIvaras from './img/inicio/logo-ivaras/logo.png';
import PreLoader from './component/Preloader.js';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Inicio2() {
  const location = useLocation();
  const shouldLoadCss = location.pathname === '/Inicio2';
  const [bodyClass, setBodyClass] = useState('body-inicio');
  useEffect(() => {
    document.body.className = bodyClass;
  }, [bodyClass]);
  useEffect(() => {
    if (shouldLoadCss) {
      import('./pages/Ambiente.css').then(() => {
        setBodyClass('body-ambientes');
        console.log('CSS cargado');
      });
    }
  }, [shouldLoadCss]);
    
return(
    <div>
        
    </div>
)}
export default Inicio2;