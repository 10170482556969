import logoivaras from '../../../img/logo/ivaras-logo2.png';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import NavBarStaff from '../../../component/navbar-staff';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function LandingCarrusel() {

    const [ambiente, setProyectos] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(`${process.env.REACT_APP_API_BASE_URL}/api/ambiente/`);
            setProyectos(result.data.ambientes);
        };

        fetchData();
    }, []);

    const showSwal = (urlAmbiente) => {
        const MySwal = withReactContent(Swal);
    
        MySwal.fire({
            title: '¿Seguro que quieres borrar este proyecto?',
            text: 'Este cambio no puede ser revertido',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Borrar',
            confirmButtonColor: 'red',
            cancelButtonText: 'Cancelar',
            cancelButtonColor: 'blue',
        }).then((result) => {
            if (result.isConfirmed) {
                // Realizar la solicitud DELETE a la API
                fetch(`${process.env.REACT_APP_API_BASE_URL}/api/ambiente/delete/${urlAmbiente}/`, {
                    method: 'DELETE',
                })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Algo salió mal al eliminar el ambiente');
                })
                .then(data => {
                    MySwal.fire(
                        'Eliminado!',
                        'El ambiente ha sido eliminado.',
                        'success'
                    );
                    // Aquí puedes actualizar el estado para reflejar la eliminación
                    window.location.reload();
                })
                .catch(error => {
                    MySwal.fire(
                        'Error!',
                        error.message,
                        'error'
                    );
                });
            }
        });
    };
    return (
        <div>
        <NavBarStaff />
        <main class="main">
            <div className="formulario-container">
            <div class="table-responsive">
    <div class="table-wrapper">
        <div class="table-title">
            <div class="row">
                <div class="col-sm-6">
                    <h2>Entornos</h2>
                </div>
                <div class="col-sm-6">
                    <Link to={'/staff/agregarentorno'} className='btn btn-success'><AddCircleIcon className='iconmas'/><span className='testo'>Agregar nuevo entorno</span></Link>
                    <Link to={'/staff/editarcontenido'} className='btn btn-success'><AddCircleIcon className='editcont'/><span className='testo'>Editar contenido de entorno</span></Link>						
                </div>
            </div>
        </div>
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th>Nombre ambiente</th>
                    <th>Tipo ambiente</th>
                    <th>Imagen entorno</th>
                    <th>Eliminar Entorno</th>
                </tr>
            </thead> 
            <tbody>
                {ambiente.map((ambiente) => (
					<tr key={ambiente.id}>
							<td>{ambiente.nombre}</td>
							<td>{ambiente.tipo_ambiente}</td>
							<td>{ambiente.ubicacion}</td>
						<td>
                            <button onClick={() => showSwal(ambiente.url)} className='delete'><DeleteIcon/></button>
                        </td>
                </tr>
                ))}
            </tbody>
        </table>
    </div>
</div>  
            </div>
        </main>
    </div>
    );
}

export default LandingCarrusel;