import React, { useEffect } from 'react'
import './preloader.css'
import { preLoaderAnim } from '../animations';
import Logo from '../img/preloading/ivaras-logo.png';
const PreLoader = () => {

        useEffect(()=>{
            preLoaderAnim()
        },[]);


    return (
        <div className="preloader">
            <div className="texts-container">
                <span>Únete, </span>
                <span>Innova, </span>
                <span>Emprende</span>
            </div>
            <div className="logo">
                <img src={Logo} alt="logo" width='300px'/>
            </div>
        </div>
    )
}

export default PreLoader