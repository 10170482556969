import logoivaras from '../../../img/logo/ivaras-logo2.png';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavBarStaff from '../../../component/navbar-staff';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';

const MySwal = withReactContent(Swal);
function EditarContenido() {
    
    const [selectedFile, setSelectedFile] = useState();
    const [selectedId, setSelectedId] = useState(1); // Añade esta línea
    const [selectedUrl, setSelectedUrl] = useState();
    const [titulo, setTitulo] = useState(''); // Añade esta línea
    const [descripcion, setDescripcion] = useState(''); // Añade esta línea
    const [objetivo, setObjetivo] = useState(''); // Añade esta línea
    const [mision_vision, setMision_vision] = useState(''); // Añade esta línea
    const [quienes_somos, setQuienes_somos] = useState(''); // Añade esta línea
    const [ambiente_proyecto, setambiente_proyecto] = useState();
    const [ambientes, setAmbientes] = useState([]);
    const [imagenes, setImagenes] = useState([]);
    const [datos, setDatos] = useState(null);
    const [url_ambiente, setUrl_ambiente] = useState('');
    const [url_ambiente1, setUrl_ambiente1] = useState('');


    useEffect(() => {
        // Función para cargar los datos del detalle basados en la URL de ambiente seleccionada
        const cargarDetalle = async () => {
          try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/detalle/ivaras/texto/?url_ambiente=${url_ambiente}`);
            const data = await response.json();
            const listaTexto = data['Lista de Texto del Ambiente de iVaras'][0];
            setDatos(listaTexto);
            setTitulo(listaTexto.titulo);
            setDescripcion(listaTexto.descripcion);
            setObjetivo(listaTexto.objetivos);
            setMision_vision(listaTexto.mision_vision);
            setQuienes_somos(listaTexto.quienes_somos);
          } catch (error) {
            console.error('Error al cargar detalle:', error);
          }
        };
    
        // Función para cargar los ambientes
        const cargarAmbientes = async () => {
          try {
            const result = await axios(`${process.env.REACT_APP_API_BASE_URL}/api/ambiente/`);
            setAmbientes(result.data.ambientes);
          } catch (error) {
            console.error('Error al cargar ambientes:', error);
          }
        };

        const cargarImagenes = async () => {
            try {
              const result = await axios(`${process.env.REACT_APP_API_BASE_URL}/api/ambiente/`);
              setImagenes(result.imgs.imagenes);
            } catch (error) {
              console.error('Error al cargar imagenes:', error);
            }
          };
    
        // Si urlAmbiente no está vacío, cargar el detalle
        if (url_ambiente) {
          cargarDetalle();
        } else {
          // Resetear los estados si no hay ambiente seleccionado
          setDatos(null);
          setTitulo('');
          setDescripcion('');
          setObjetivo('');
          setMision_vision('');
          setQuienes_somos('');
        }
    
        cargarAmbientes();
      }, [url_ambiente]);

      const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    }

    const handleSelectChange = (event) => { // Añade esta función
        setSelectedId(event.target.value);
    }
    const handleUrl = (event) => {
        setSelectedUrl(event.target.value);
    }

    const handleTituloChange = (event) => {
        setTitulo(event.target.value);
    } // Añade esta función

    const handleDescripcionChange = (event) => {
        setDescripcion(event.target.value);
    } // Añade esta función
    const handleMisionVisionChange = (event) => {
        setMision_vision(event.target.value);
    } // Añade esta función
    const handleObjetivosChange = (event) => {
        setObjetivo(event.target.value);
    }
    const handleQuienesSomosChange = (event) => {
        setQuienes_somos(event.target.value);
     }
     const handleUrlEntorno = (event) => {
        setUrl_ambiente(event.target.value);
    }

    


    const validateForm = () => {
        // Asegúrate de que se seleccionó un archivo
        if (!selectedFile) {
            alert('Por favor, selecciona un archivo.');
            return false;
        }

        // Asegúrate de que se seleccionó un id
        if (!selectedId) {
            alert('Por favor, selecciona un id.');
            return false;
        }

        // Si todo está bien, retorna true
        return true;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validar el formulario antes de enviar los datos
        if (!validateForm()) {
            return;
        }

        const formData = new FormData();
        formData.append('img', selectedFile);
        formData.append('id', selectedId);
        formData.append('url_ambiente1', selectedUrl);
        console.log('img:', formData.get('img'));
        console.log('id:', formData.get('id'));
        console.log('url:', formData.get('url_ambiente1'));
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/detalle/ivaras/upload/`, formData);
            console.log(response.data);
            MySwal.fire({
                title: '¡Enviado!',
                text: 'Se han enviado las imagenes correctamente.',
                icon: 'success'
            });
        } catch (error) {
            console.error(error);
        }
    }
    const handleSubmit2 = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('id_ambiente', '1');
        formData.append('titulo', titulo);
        formData.append('descripcion', descripcion);
        formData.append('objetivos', objetivo);
        formData.append('mision_vision', mision_vision);
        formData.append('quienes_somos', quienes_somos);
        formData.append('url_ambiente', url_ambiente);
        
        
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/detalle/ivaras/texto/upload/`, formData);
            console.log(response.data);
            MySwal.fire({
                title: '¡Enviado!',
                text: 'Has enviado los textos correctamente.',
                icon: 'success'
            });
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const urlFormateada = url_ambiente.toLowerCase().replace(/\s/g, '');
        setSelectedUrl(urlFormateada);
      }, [url_ambiente]);


    return (
        <div>
            <NavBarStaff />
            <main class="main">
            <div className="formulario-container">
                <div class="formulario">
                    <form name="formulario" onSubmit={handleSubmit}>
                        <h2>Subir imagenes</h2>
                        <div class="form-group">
                        <label for="exampleFormControlSelect1">Id de la imagen</label>
                            <select class="form-control" id="exampleFormControlSelect1" value={selectedId} onChange={handleSelectChange}>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="formFile" class="form-label">Subir imagen del entorno (formato .png 1068x1012)</label>
                            <input class="form-control" type="file" id="formFile" onChange={handleFileChange}></input>
                        </div>

                        <button type="submit" className="btn btn-primary btn-lg boton">Subir</button>
                    </form>
                    <Link to={'/staff/entornos'}><button type="button" class="btn btn-danger btn-lg boton">Volver</button></Link>
                </div>
                <div className="formulario-2">
                <form name="formulario" onSubmit={handleSubmit2}>
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Entorno</label>
                        <select onChange={handleUrlEntorno} value={url_ambiente} className='form-control'>   
                            <option value="">-- Selecciona el Entorno --</option>
                            {ambientes.map((ambiente) => (
                            <option value={ambiente.url}>{ambiente.nombre}</option>
                             ))}
                        </select>
                    </div>
                    <h2>Subir texto</h2>
                    <div class="form-group">
                        <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">Titulo del entorno</label>
                            <input class="form-control" type="text" id="formFile" onChange={handleTituloChange} required value={titulo}></input>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Descripcion del entorno</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={handleDescripcionChange} required value={descripcion}></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Objetivos</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={handleObjetivosChange} required value={objetivo}></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Misión y Visión</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={handleMisionVisionChange} required value={mision_vision}></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Quienes somos</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={handleQuienesSomosChange} required value={quienes_somos}></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary btn-lg boton">Subir</button>
                </form>
                </div>
                </div>
            </main>
        </div>
    );
}
export default EditarContenido;