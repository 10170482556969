import logoivaras from '../../../img/logo/ivaras-logo2.png';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NavBarStaff from '../../../component/navbar-staff';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link } from 'react-router-dom'


const MySwal = withReactContent(Swal);
function Redes(){

    const [id, setid] = useState(''); // Añade esta línea
    const [imagen, setimagen] = useState(null); // Añade esta línea
    const [link_red, setlink_red] = useState(null); // Añade esta línea

    const handleidSet = (event) => { 
        setid(event.target.value);
    }

    const handleImagenChange = (event) => { 
        setimagen(event.target.files[0]);
    }

    const handleLinkChange = (event) => { 
        setlink_red(event.target.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('id', id);
        formData.append('imagen', imagen);
        formData.append('link_red', link_red);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/redes/post/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            MySwal.fire({
                title: '¡Enviado!',
                text: 'Se ha enviado la imagen correctamente.',
                icon: 'success'
            });
            // Aquí puedes redirigir al usuario o mostrar un mensaje de éxito
        } catch (error) {
            console.error(error);
            // Aquí puedes mostrar un mensaje de error
        }
        
    };
    
    return(
        <div>
            <NavBarStaff />
            <main class="main">
                <div className="formulario-container">
                    <div className="formulario-3">
                        <form name="formulario" onSubmit={handleSubmit}>
                            <h2>Agregar imagen</h2>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Id imagen</label>
                                <select class="form-select form-select-sm" aria-label=".form-select-sm example" value={id} onChange={handleidSet}>
                                    <option value={1}>Instagram</option>
                                    <option value={2}>Linkedin</option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Imagen</label>
                                <input class="form-control" type="file" id="formFile" onChange={handleImagenChange}></input>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">URL de la imagen</label>
                                <input class="form-control" type="text" id="formFile" value={link_red} onChange={handleLinkChange}></input>
                            </div>
                            <div className='botones'>
                                <button type="submit" class="btn btn-lg btn-primary">Subir</button>
                                <Link to={'/staff/entornos'}><button type="button" class="btn btn-danger btn-lg">Volver</button></Link>
                            </div>
                        </form>
                    </div>
                </div>
            </main> 
        </div>
    );
}
export default Redes;