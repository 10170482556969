import logoivaras from '../../../img/logo/ivaras-logo2.png';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavBarStaff from '../../../component/navbar-staff';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';

const MySwal = withReactContent(Swal);
function Proyectos() {
	const [proyectos, setProyectos] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(`${process.env.REACT_APP_API_BASE_URL}/api/proyectos/post/`);
            setProyectos(result.data);
        };

        fetchData();
    }, []);

    const showSwal = (urlProyecto) => {
        const MySwal = withReactContent(Swal);
    
        MySwal.fire({
            title: '¿Seguro que quieres borrar este proyecto?',
            text: 'Este cambio no puede ser revertido',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Borrar',
            confirmButtonColor: 'red',
            cancelButtonText: 'Cancelar',
            cancelButtonColor: 'blue',
        }).then((result) => {
            if (result.isConfirmed) {
                // Realizar la solicitud DELETE a la API
                fetch(`${process.env.REACT_APP_API_BASE_URL}/api/proyectos/delete/${urlProyecto}/`, {
                    method: 'DELETE',
                })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Algo salió mal al eliminar el proyecto');
                })
                .then(data => {
                    MySwal.fire(
                        'Eliminado!',
                        'El proyecto ha sido eliminado.',
                        'success'
                    );
                    // Aquí puedes actualizar el estado para reflejar la eliminación
                    window.location.reload();
                })
                .catch(error => {
                    MySwal.fire(
                        'Error!',
                        error.message,
                        'error'
                    );
                });
            }
        });
    };
    return (
        <div>
            <NavBarStaff />
            <main class="main">
                <div className="formulario-container">
                <div class="table-responsive">
		<div class="table-wrapper">
			<div class="table-title">
				<div class="row">
					<div class="col-sm-6">
						<h2>Proyectos</h2>
					</div>
					<div class="col-sm-6">
						<Link to={'/staff/agregarproyectos'} className='btn btn-success'><AddCircleIcon className='iconmas'/><span className='testo'>Agregar nuevo proyecto</span></Link>
						<Link to={'/staff/editarproyecto'} className='btn btn-success'><AddCircleIcon className='editcont'/><span className='testo'>Editar contenido de proyectos</span></Link>						
					</div>
				</div>
			</div>
			<table class="table table-striped table-hover">
				<thead>
					<tr>
						<th>Nombre</th>
						<th>Tipo</th>
						<th>Entorno</th>
						<th>Fecha creación</th>
						<th>Fecha de termino</th>
						<th>Acciones</th>
					</tr>
				</thead>
				<tbody>
					{proyectos.map((proyecto) => (
					<tr key={proyectos.id}>
							<td>{proyecto.nombre_proyecto}</td>
							<td>{proyecto.tipo}</td>
							<td>{proyecto.ambiente_proyecto}</td>
							<td>{proyecto.fecha_creacion}</td>
							<td>{proyecto.fecha_termino}</td>
						<td>
							<button onClick={() => showSwal(proyecto.url_name)} className='delete'><DeleteIcon/></button>
						</td>
					</tr>
					))}
				</tbody>
			</table>
		</div>
	</div>  
                </div>
            </main>
        </div>
    );
}
export default Proyectos