import logoivaras from '../../../img/logo/ivaras-logo2.png';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavBarStaff from '../../../component/navbar-staff';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom'


const MySwal = withReactContent(Swal);
function Agregarentorno() {
    const [nombre, setNombre] = useState('');
    const [url, setUrl] = useState('');
    const [video_ambiente, setVideo_ambiente] = useState('');
    const [imagen, setImagen] = useState(null);
    const [tipoAmbiente, setTipoAmbiente] = useState('Proyectos');
    const handleNombreEntorno = (event) => { 
        setNombre(event.target.value);
    }
    const handleUrlEntorno = (event) => {
        setUrl(event.target.value);
    }
    const handleTipoAmbiente = (event) => {
        setTipoAmbiente(event.target.value);
    }
    const handleVideo_ambiente = (event) => {
        setVideo_ambiente(event.target.value);
    }
    const handleImagen = (event) => {
        setImagen(event.target.files[0]);
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('nombre', nombre);
        formData.append('url', url);
        formData.append('video_ambiente', video_ambiente);
        formData.append('imagen', imagen);
        formData.append('tipo_ambiente', tipoAmbiente);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/ambiente/`, formData, {
                headers: { 
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            MySwal.fire({
                title: '¡Enviado!',
                text: 'Se ha creado el ambiente correctamente.',
                icon: 'success'
            });
            // Aquí puedes redirigir al usuario o mostrar un mensaje de éxito
        } catch (error) {
            console.error(error);
            // Aquí puedes mostrar un mensaje de error
        }
        try {
            event.preventDefault();
            const formData = new FormData();
            formData.append('id_ambiente', '1');
            formData.append('titulo', 'titulo ejemplo');
            formData.append('descripcion', 'descripcion ejemplo');
            formData.append('objetivos', 'objetivos ejemplo');
            formData.append('mision_vision', 'mision_vision ejemplo');
            formData.append('quienes_somos', 'quienes_somos ejemplo');
            formData.append('url_ambiente', url);
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/detalle/ivaras/texto/upload/`, formData);

        } catch (error) {
            console.error(error);
        }
        
    };

    useEffect(() => {
        const urlFormateada = nombre.toLowerCase().replace(/\s/g, '');
        setUrl(urlFormateada);
      }, [nombre]);
      /*
      <div class="mb-3">
      <label for="exampleFormControlTextarea1" class="form-label">URL entorno</label>
      <input class="form-control" type="text" id="formFile" value={url} onChange={handleUrlEntorno}></input>
      </div>
      */
    return (
        <div>
            <NavBarStaff />
            <main class="main">
                <div className="formulario-container">
                    <div className="formulario-3">
                        <form name="formulario" onSubmit={handleSubmit}>
                            <h2>Agregar entorno</h2>
                            <div class="form-group">
                                <div class="mb-3">
                                    <label for="exampleFormControlTextarea1" class="form-label">Nombre del entorno</label>
                                    <input class="form-control" type="text" id="formFile" value={nombre} onChange={handleNombreEntorno}></input>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Tipo entorno</label>
                                <select class="form-select form-select-sm" aria-label=".form-select-sm example" value={tipoAmbiente} onChange={handleTipoAmbiente}>
                                    <option value="Proyectos">Proyectos</option>
                                    <option value="Eventos">Eventos</option>
                                </select>
                            </div>


                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">URL del Video (youtube)</label>
                                <input class="form-control" type="text" id="formFile" value={video_ambiente} onChange={handleVideo_ambiente}></input>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Imagen del entorno (formato .png 1720x1112)</label>
                                <input class="form-control" type="file" id="formFile" onChange={handleImagen}></input>
                            </div>
                            <div className='botones'>
                                <button type="submit" class="btn btn-lg btn-primary">Subir</button>
                                <Link to={'/staff/entornos'}><button type="button" class="btn btn-danger btn-lg">Volver</button></Link>
                            </div>
                        </form>
                    </div>
                </div>
            </main> 
        </div>
    );
}

export default Agregarentorno;