import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from 'axios';
import { Link } from 'react-router-dom';

/*Lo sentimos mucho por lo que estan a punto de ver, les deseamos mucha suerte*/ 

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import { useLocation } from 'react-router-dom';
import { Autoplay, Pagination, Navigation, EffectCoverflow } from 'swiper/modules';
import img_jc from '../img/ambientes/lateral-der/JUAN_CARLOS.png';
import img_mf from '../img/ambientes/lateral-der/MAURICIO_FRUGONE.png';
import img_no from '../img/ambientes/lateral-der/NICOLAS_OSBEN.png';
import img_cc from '../img/ambientes/lateral-der/CARLA_CHAVES.png';
import img_ba from '../img/ambientes/lateral-der/Bárbara_Ahumada.png';
import img_pq1 from '../img/ambientes/lateral-der/IG.png';
import img_pq2 from '../img/ambientes/lateral-der/IN.png';
import img_flecha2 from '../img/ambientes/centro/flecha2.png';
import PreLoader from '../component/Preloader.js';
import img_ins from '../img/ambientes/lateral-der/image2.png';
import img_link from '../img/ambientes/lateral-der/image.png';

function Ambientes() {
  const [imagenLanding, setImagenLanding] =  useState([]);
  const [error, setError] = useState(null);
  
  const getLandingInferior = async () => {
    const respuesta = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/landing/inferior/?descripcion?Lan_Inferior`);
    if (Array.isArray(respuesta.data.lan_inferiores)) {
      setImagenLanding(respuesta.data.lan_inferiores[0]);
    } else {
      console.error('No es un arreglo:', respuesta.data);
    }
  };
  
  useEffect(()=>{
    getLandingInferior();
  },[]);
  
  const [datosCargados, setDatosCargados] = useState(false);

  
const [mes, setMes] = useState('');
const [dia, setDia] = useState('');
const [hora, setHora] = useState('');
const location = useLocation();
const shouldLoadCss = location.pathname === '/';
const [bodyClass, setBodyClass] = useState('default-background');
const [ambientes, setAmbientes] = useState([]);
useEffect(() => {
  const intervalId = setInterval(() => {
    const fechaActual = new Date();
    let meses = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO','AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];
    let showMes = meses[fechaActual.getMonth()];
    let showDia = fechaActual.getDate();
    let hora = fechaActual.getHours();
    let minutos = fechaActual.getMinutes();
    let minutosFormateados = String(minutos).padStart(2, '0');
    let horaCompleta = `${hora}:${minutosFormateados}`;
    setMes(showMes);
    setDia(showDia);
    setHora(horaCompleta);
  }, 0); 


  return () => clearInterval(intervalId);
}, []);



useEffect(() => {
  if (shouldLoadCss) {
    import('./Ambiente.css').then(() => {
      setBodyClass('body-ambientes');
      console.log('CSS cargado');
    });
  }
}, [shouldLoadCss]);

useEffect(() => {
  document.body.className = bodyClass;
}, [bodyClass]);
const swiperRef = useRef(null);
useEffect(() => {
  axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/ambiente/`)
    .then(response => {
      setAmbientes(response.data.ambientes);
      setDatosCargados(true); // Indica que los datos se han cargado
    })
    .catch(error => {
      console.error('Hubo un error al obtener los datos:', error);
    });
}, []);

const [data, setData] = useState([]);

useEffect(() => {
  fetch(`${process.env.REACT_APP_API_BASE_URL}/api/landing/izquierdo/`)
      .then(response => response.json())
      .then(data => setData(data['lan izquierdos']))
}, []);

useEffect(() => {
  if (swiperRef.current && swiperRef.current.swiper) {
    swiperRef.current.swiper.update();
  }
}, [ambientes]); // Se ejecuta cuando 'ambientes' cambia

const [data2, setData2] = useState([]);

useEffect(() => {
  fetch(`${process.env.REACT_APP_API_BASE_URL}/api/redes/`)
      .then(response => response.json())
      .then(data2 => setData2(data2['Redes']))
}, []);


const [data3, setData3] = useState([]);

useEffect(() => {
  fetch(`${process.env.REACT_APP_API_BASE_URL}/api/noticias/`)
      .then(response => response.json())
      .then(data3 => setData3(data3['Noticias']))
}, []);

//PANEL IZQUIERDO
/*
<div className="panel-izq">
        
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
        {data.map(item => (
          <SwiperSlide key={item.id}>
            <img src={`http://localhost:8000/${item.ubicacion}`} alt="slide_image" className='slide_lateral' />
          </SwiperSlide>
        ))}
        </Swiper>

        </div>

*/
const [movimiento, setMovimiento] = useState(0);

useEffect(() => {
  const intervalId = setInterval(() => {
    
    setMovimiento(prevMovimiento => (prevMovimiento === 0 ? 20 : 0));
  }, 3000);

  return () => clearInterval(intervalId); 
}, []); 


const [aplicarAnimacion, setAplicarAnimacion] = useState(false);

useEffect(() => {
  if (datosCargados) {
    // Inicia un temporizador para aplicar la animación después de un retraso
    const timer = setTimeout(() => {
      setAplicarAnimacion(true);
    }, 500); // Retraso de 500 milisegundos

    // Limpia el temporizador si el componente se desmonta
    return () => clearTimeout(timer);
  }
}, [datosCargados]);



  return (
    <div>
      <html lang="en">
      <head>
      
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="preconnect"
          href="https://fonts.googleapis.com"
        />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap"
          rel="stylesheet"
        />
        <title>Ambientes - Innovaras</title>
      </head>
      <>
      <PreLoader/>
      </>
    </html>

    <div className='scollxfavor'>
      <div className="contenedores" >
        

        <div className="panel-centro">

          <div className="panel-centro-centro">
          {datosCargados && (
              <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                loop={true}
                slidesPerView={'2'}
                ref={swiperRef}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 30,
                  depth: 100,
                  modifier: 2.5,
                }}
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                  clickable: true,
                }}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className={`swiper_container ${aplicarAnimacion ? 'fade-in-animation' : ''}`}
              >
              {ambientes.map(ambiente => (
                  <SwiperSlide key={ambiente.id}>
                      <a href={`../detalle/${ambiente.url}`} onClick={() => window.location.href=`../detalle/${ambiente.url}`}>
                          <img src={`${process.env.REACT_APP_API_BASE_URL}/${ambiente.ubicacion}`} alt={ambiente.nombre} className='slide_carrusel'/>
                      </a>
                  </SwiperSlide>
              ))}
              




                  <div className="slider-controler">
                    <div className="swiper-button-prev slider-arrow">
                      <ion-icon name="arrow-back-outline"></ion-icon>
                    </div>
                  <div className="swiper-button-next slider-arrow">
                    <ion-icon name="arrow-forward-outline"></ion-icon>
                    </div>
                    <div className="swiper-pagination"></div>
                  </div>
                </Swiper>
          )}
          </div>

          <div className="panel-centro-abajo">
          <a href='https://forms.office.com/Pages/ResponsePage.aspx?id=Wgv9cmqK_0yJ9r3pYffiUK3GqQO4mXhKo9PGg80pb7pUOE5PVjJOUVdPRkFMT1g2MVc0SUtMTzNQSS4u' className="banner_image">{imagenLanding && <img className="banner_image" src={`${process.env.REACT_APP_API_BASE_URL}/${imagenLanding.ubicacion}`} alt="Imagen Landing"/>}</a>
          <a href='https://www.instagram.com/duocucsedeantoniovaras/?hl=es-la'><img src={img_pq1} className='imagen-pequena'/></a>
          <a href='https://cl.linkedin.com/company/duocuc'><img src={img_pq2} className='imagen-pequena'/></a>

          </div>

        </div>
        <div className="panel-der">
 
                <Swiper
                  spaceBetween={20}
                  centeredSlides={true}
                  autoplay={{
                    delay: 7500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Autoplay, Pagination]}
                  className="mySwiper"
                >
                  <SwiperSlide><img src={img_jc} alt="slide_image" className='slide_lateral2' /></SwiperSlide>
                  <SwiperSlide><img src={img_mf} alt="slide_image" className='slide_lateral2' /></SwiperSlide>
                  <SwiperSlide><img src={img_no} alt="slide_image" className='slide_lateral2' /></SwiperSlide>
                  <SwiperSlide><img src={img_cc} alt="slide_image" className='slide_lateral2' /></SwiperSlide>
                  <SwiperSlide><img src={img_ba} alt="slide_image" className='slide_lateral2' /></SwiperSlide>
                </Swiper>
        </div>
        </div>
       
        
        <div className='contenedorflecha'>
        <img
        className="flecha"
        src={img_flecha2} // Actualiza con la ruta de tu imagen de flecha
        alt="Flecha"
        style={{
          transform: `translateY(${movimiento}px)`,
          transition: 'transform 0.5s ease-in-out',
        }}
      />
      </div>

        
        <div>
        <div className="panel-centro-centro2">
                  
        <div className="panel-izq">
        
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
        {data.map(item => (
          <SwiperSlide key={item.id}>
            <img src={`${process.env.REACT_APP_API_BASE_URL}/${item.ubicacion}`} alt="slide_image" className='slide_lateral' />
          </SwiperSlide>

        ))}
        </Swiper>
                <div className='text-loco'>
                  <p className='text-loco'>TALLERES</p>
                </div>
        </div>

        <div className='panel-centro-centro3'>
                <Swiper
                  spaceBetween={20}
                  centeredSlides={true}
                  autoplay={{
                    delay: 10000,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                {data2.map(red => (
                  <SwiperSlide key={red.id}>
                    <a href={red.link_red} className='slide_lateral'><img src={`${process.env.REACT_APP_API_BASE_URL}/${red.ubicacion}`} alt="slide_image" className='slide_lateral' /></a>
                  </SwiperSlide>
                ))}
                </Swiper>
                <div className='text-loco'>
                <p className='text-loco'>NUESTRAS REDES</p>
                </div>
        </div>

        <div className='panel-centro-derecha2'>
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
              >
              {data3.map(not => (
                <SwiperSlide key={not.id}>
                  <img src={`${process.env.REACT_APP_API_BASE_URL}/${not.ubicacion}`} alt="slide_image" className='slide_lateral' />
                </SwiperSlide>
              ))}
              </Swiper>
              <div className='text-loco'>
              <p className='text-loco'>NOTICIAS</p>
              </div>
        </div>
        

        </div>
        </div>
      </div>

    </div>
  );
}

export default Ambientes;

