import logoivaras from '../../../img/logo/ivaras-logo2.png';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavBarStaff from '../../../component/navbar-staff';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';

const MySwal = withReactContent(Swal);
function Agregareventos() {
    const [nombre_evento, setnombre_evento] = useState(''); // Añade esta línea
    const [icono_evento, seticono_evento] = useState(null); // Añade esta línea
    const [ambiente_evento, setambiente_evento] = useState(); // Añade esta línea
    const [url_name, seturl_name] = useState(''); // Añade esta línea
    const [fecha_creacion, setfecha_creacion] = useState(''); // Añade esta línea
    const [fecha_termino, setfecha_termino] = useState(''); // Añade esta línea
    const [eve_desc, seteve_desc] = useState(''); // Añade esta línea
    const [eve_obj, seteve_obj] = useState(''); // Añade esta línea
    const [eve_result, seteve_result] = useState(''); // Añade esta línea    
    const [tipo, settipo] = useState(''); // Añade esta línea
    const [urlvideo_name, seturlvideo_name] = useState(''); // Añade esta línea
    const [ambientes, setAmbientes] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(`${process.env.REACT_APP_API_BASE_URL}/api/ambiente/?ambiente=Eventos`);
            setAmbientes(result.data.ambientes);
        };

        fetchData();
    }, []);
    const handlenombre_eventoChange = (event) => { // Añade esta función
        setnombre_evento(event.target.value);
    }
    const handleicono_evento = (e) => { // Añade esta función
        seticono_evento(e.target.files[0]);
    }
    const hnadleambiente_eventoChange = (event) => { // Añade esta función
        setambiente_evento(event.target.value);
    }
    const handleurl_nameChange = (event) => { // Añade esta función
        seturl_name(event.target.value);
    }
    const handlefecha_creacionChange = (event) => { // Añade esta función
        setfecha_creacion(event.target.value);
    }
    const handlefecha_terminoChange = (event) => { // Añade esta función
        setfecha_termino(event.target.value);
    }
    const handleeve_descChange = (event) => { // Añade esta función
        seteve_desc(event.target.value);
    }
    const handleeve_objChange = (event) => { // Añade esta función
        seteve_obj(event.target.value);
    }

    const handleeve_resultChange = (event) => { // Añade esta función
        seteve_result(event.target.value);
    }

    const handleurlvideo_nameChange = (event) => {
        seturlvideo_name(event.target.value);
    } // Añade esta función


    const handleSubmit2 = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('nombre_evento', nombre_evento);
        formData.append('icono_evento', icono_evento);
        formData.append('ambiente_evento', ambiente_evento);
        formData.append('url_name', url_name);
        formData.append('fecha_creacion', fecha_creacion);
        formData.append('fecha_termino', fecha_termino);
        formData.append('eve_desc', eve_desc);
        formData.append('eve_obj', eve_obj);
        formData.append('eve_result', eve_result);
        formData.append('tipo', 'Evento');
        console.log(tipo)

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/eventos/post/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            MySwal.fire({
                title: '¡Enviado!',
                text: 'Has añadido un proyecto correctamente.',
                icon: 'success'
            });
        } catch (error) {
            console.error(error);
            MySwal.fire({
                title: 'Oops...',
                text: 'No se pudo añadir un proyecto.',
                icon: 'error'
            });
        }
    }

    return (
        <div>
            <NavBarStaff />
            <main class="main">
                <div className="formulario-container">
                    <div className="formulario-3">
                        <form name="formulario" onSubmit={handleSubmit2} enctype="multipart/form-data">
                            <h2>Agregar Evento</h2>
                            <div class="form-group">
                                <div class="mb-3">
                                    <label for="exampleFormControlTextarea1" class="form-label">Nombre del evento</label>
                                    <input onChange={handlenombre_eventoChange} value={nombre_evento} class="form-control" type="text" id="formFile"></input>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Entorno del evento</label>
                                <select onChange={hnadleambiente_eventoChange} value={ambiente_evento} className='form-control'>   
                                    <option value="">-- Selecciona el Entorno --</option>
                                    {ambientes.map((ambiente) => (
                                    <option value={ambiente.url}>{ambiente.nombre}</option>
                                    ))}
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Fecha de inicio del evento</label>
                                <input onChange={handlefecha_creacionChange} value={fecha_creacion} class="form-control" type="date" id="formFile"></input>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Fecha de cierre del evento</label>
                                <input onChange={handlefecha_terminoChange} value={fecha_termino} class="form-control" type="date" id="formFile"></input>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Descripción del evento</label>
                                <textarea onChange={handleeve_descChange} value={eve_desc} class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Objetivos del evento</label>
                                <textarea onChange={handleeve_objChange} value={eve_obj} class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Resultados esperados</label>
                                <textarea onChange={handleeve_resultChange} value={eve_result} class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Nombre de la URL</label>
                                <textarea onChange={handleurl_nameChange} value={url_name} class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Nombre de la URL del Video(youtube)</label>
                                <textarea onChange={handleurlvideo_nameChange} value='' class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Icono</label>
                                <input onChange={handleicono_evento} class="form-control" type="file" id="formFile"></input>
                            </div>
                            <div className='botones'>
                                <button type="submit" class="btn btn-lg btn-primary">Subir</button>
                            </div>
                        </form>
                        <div className='botones'>
                            <Link to={'/staff/entorno/Eventos'}><button type="button" class="btn btn-danger btn-lg">Volver</button></Link>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
export default Agregareventos