import logoivaras from '../../../img/logo/ivaras-logo2.png';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavBarStaff from '../../../component/navbar-staff';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';

const MySwal = withReactContent(Swal);
function EditarProyectos2() {
    const [id_proyecto, setid_proyecto]= useState('');
    const [nombre_proyecto, setnombre_proyecto] = useState(''); // Añade esta línea
    const [icono_proyecto, seticono_proyecto] = useState(null); // Añade esta línea
    const [ambiente_proyecto, setambiente_proyecto] = useState(); // Añade esta línea
    const [url_name, seturl_name] = useState(''); // Añade esta línea
    const [fecha_creacion, setfecha_creacion] = useState(''); // Añade esta línea
    const [fecha_termino, setfecha_termino] = useState(''); // Añade esta línea
    const [pro_desc, setpro_desc] = useState(''); // Añade esta línea
    const [pro_obj, setpro_obj] = useState(''); // Añade esta línea
    const [pro_result, setpro_result] = useState(''); // Añade esta línea    
    const [tipo, settipo] = useState(''); // Añade esta línea
    const [url_video, seturl_video] = useState(''); // Añade esta línea


    const [ambientes, setAmbientes] = useState([]);
    const [proyectos, setProyectos] = useState([]);
    const [urlAmbiente, setUrlAmbiente] = useState('');
    const [proyectoSeleccionado, setProyectoSeleccionado] = useState(null);

    // Función para manejar el cambio de selección
    const handleUrlEntorno = (event) => {
      setUrlAmbiente(event.target.value);
    };
  
    // Efecto para cargar los datos de la API
    useEffect(() => {
      // Función para obtener los datos de la API
      const fetchAmbientes = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/ambiente/`);
          const data = await response.json();
          setAmbientes(data.ambientes);
        } catch (error) {
          console.error('Error al obtener los datos de la API:', error);
        }
      };
  
      fetchAmbientes();
    }, []);
    useEffect(() => {
        // Verifica que urlAmbiente no esté vacío
        if (urlAmbiente) {
          // Función para obtener los datos de la API
          const fetchProyectos = async () => {
            try {
              const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/proyectos/filter/?ambiente_proyecto=${urlAmbiente}`);
              const data = await response.json();
              setProyectos(data);
            } catch (error) {
              console.error('Error al obtener los datos de la API:', error);
            }
          };
    
          fetchProyectos();
        } else {
          // Si urlAmbiente está vacío, limpia la lista de proyectos
          setProyectos([]);
        }
      }, [urlAmbiente]);
      const handleProyectoSeleccionado = (event) => {
        const urlProyecto = event.target.value;
        const proyecto = proyectos.find(p => p.url_name === urlProyecto);
        setProyectoSeleccionado(proyecto);
        // Actualiza los estados con la información del proyecto seleccionado
        if (proyecto) {
            setid_proyecto(proyecto.id);
            setnombre_proyecto(proyecto.nombre_proyecto);
            setfecha_creacion(proyecto.fecha_creacion);
            setfecha_termino(proyecto.fecha_termino);
            setpro_desc(proyecto.pro_desc);
            setpro_obj(proyecto.pro_obj);
            setpro_result(proyecto.pro_result);
            seturl_name(proyecto.url_name);
            seturl_video(proyecto.url_video);
            seticono_proyecto(proyecto.icono_proyecto);
            setambiente_proyecto(proyecto.ambiente_proyecto);
            settipo(proyecto.tipo);

            // ... y así sucesivamente para los demás campos
        }
    };
    const handleInicioProyecto = (event) => {
      setfecha_creacion(event.target.value);
    };
    const handleFechaTermino = (event) => {
      setfecha_termino(event.target.value);
    };
    const handleProDesc = (event) => {
      setpro_desc(event.target.value);
    };
    const handleProObj = (event) => {
      setpro_obj(event.target.value);
    };
    const handlePro_Result = (event) => {
      setpro_result(event.target.value);
    };
    const handleProUrl = (event) => {
      seturl_video(event.target.value);
    };
    const handleIcono = (event) => {
      seticono_proyecto(event.target.files[0]);
    };




    const handleSubmit2 = async (event) => {
      event.preventDefault();
      const formData = new FormData();
      formData.append('id',id_proyecto);
      formData.append('nombre_proyecto', nombre_proyecto);
      formData.append('icono_proyecto', icono_proyecto);
      formData.append('ambiente_proyecto', ambiente_proyecto);
      formData.append('url_name', url_name);
      formData.append('fecha_creacion', fecha_creacion);
      formData.append('fecha_termino', fecha_termino);
      formData.append('pro_desc', pro_desc);
      formData.append('pro_obj', pro_obj);
      formData.append('pro_result', pro_result);
      formData.append('tipo', tipo);
      formData.append('url_video', url_video);

      try {
          const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/proyectos/replace/?url_name=${url_name}`, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });
          console.log(response.data);
          MySwal.fire({
              title: '¡Enviado!',
              text: 'Has añadido un proyecto correctamente.',
              icon: 'success'
          });
      } catch (error) {
          console.error(error);
          MySwal.fire({
              title: 'Oops...',
              text: 'No se pudo añadir un proyecto.',
              icon: 'error'
          });
      }
  }
    return (
        <div>
            <NavBarStaff />
            <main class="main">
                <div className="formulario-container">
                    <div className="formulario-3">
                        <form name="formulario"  enctype="multipart/form-data" onSubmit={handleSubmit2}>
                            <h2>Editar proyecto</h2>
                            <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">Entorno del proyecto</label>
                            <select onChange={handleUrlEntorno} value={urlAmbiente} className='form-control'>   
                            <option value="">-- Selecciona el Entorno --</option>
                            {ambientes.map((ambiente) => (
                            <option value={ambiente.url}>{ambiente.nombre}</option>
                             ))}
                            </select>
                            </div>
                            <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">Proyecto a editar</label>
                            <select onChange={handleProyectoSeleccionado} className='form-control'>
                            <option value="">-- Selecciona el Proyecto --</option>
                            {proyectos.map((proyecto, index) => (
                                <option key={index} value={proyecto.url_name}>{proyecto.nombre_proyecto}</option>
                            ))}
                            </select>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Fecha de inicio del proyecto</label>
                                <input  class="form-control" type="date" id="formFile" onChange={handleInicioProyecto} value={fecha_creacion}></input>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Fecha de cierre del proyecto</label>
                                <input  class="form-control" type="date" id="formFile" onChange={handleFechaTermino} value={fecha_termino}></input>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Descripción del proyecto</label>
                                <textarea  class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={handleProDesc} value={pro_desc}></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Objetivos del proyecto</label>
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={handleProObj} value={pro_obj}></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Resultados esperados</label>
                                <textarea  class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={handlePro_Result} value={pro_result}></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Nombre de la URL del Video(youtube)</label>
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={handleProUrl} value={url_video}></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label" >Icono</label>
                                <input  class="form-control" type="file" id="formFile" onChange={handleIcono}></input>
                            </div>
                            <div className='botones'>
                                <button type="submit" class="btn btn-lg btn-primary">Subir</button>
                            </div>
                        </form>
                        <div className='botones'>
                            <Link to={'/staff/entorno/Proyectos'}><button type="button" class="btn btn-danger btn-lg">Volver</button></Link>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
export default EditarProyectos2