import logoivaras from '../../../img/logo/ivaras-logo2.png';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavBarStaff from '../../../component/navbar-staff';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';

const MySwal = withReactContent(Swal);
function Agregarproyectos() {
    const [nombre_proyecto, setnombre_proyecto] = useState(''); // Añade esta línea
    const [icono_proyecto, seticono_proyecto] = useState(null); // Añade esta línea
    const [ambiente_proyecto, setambiente_proyecto] = useState(); // Añade esta línea
    const [url_name, seturl_name] = useState(''); // Añade esta línea
    const [fecha_creacion, setfecha_creacion] = useState(''); // Añade esta línea
    const [fecha_termino, setfecha_termino] = useState(''); // Añade esta línea
    const [pro_desc, setpro_desc] = useState(''); // Añade esta línea
    const [pro_obj, setpro_obj] = useState(''); // Añade esta línea
    const [pro_result, setpro_result] = useState(''); // Añade esta línea    
    const [tipo, settipo] = useState(''); // Añade esta línea
    const [url_video, seturl_video] = useState(''); // Añade esta línea
    const [ambientes, setAmbientes] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const result = await axios(`${process.env.REACT_APP_API_BASE_URL}/api/ambiente/?ambiente=Proyectos`);
            setAmbientes(result.data.ambientes);
        };

        fetchData();
    }, []);
    const handlenombre_proyectoChange = (event) => { // Añade esta función
        setnombre_proyecto(event.target.value);
    }
    const handleicono_proyecto = (e) => { // Añade esta función
        seticono_proyecto(e.target.files[0]);
    }
    const hnadleambiente_proyectoChange = (event) => { // Añade esta función
        setambiente_proyecto(event.target.value);
    }
    const handleurl_nameChange = (event) => { // Añade esta función
        seturl_name(event.target.value);
    }
    const handlefecha_creacionChange = (event) => { // Añade esta función
        setfecha_creacion(event.target.value);
    }
    const handlefecha_terminoChange = (event) => { // Añade esta función
        setfecha_termino(event.target.value);
    }
    const handlepro_descChange = (event) => { // Añade esta función
        setpro_desc(event.target.value);
    }
    const handlepro_objChange = (event) => { // Añade esta función
        setpro_obj(event.target.value);
    }

    const handlepro_resultChange = (event) => { // Añade esta función
        setpro_result(event.target.value);
    }

    const handleurl_videoChange = (event) => { //video
        seturl_video(event.target.value);
    } // Añade esta función


    const handleSubmit2 = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('nombre_proyecto', nombre_proyecto);
        formData.append('icono_proyecto', icono_proyecto);
        formData.append('ambiente_proyecto', ambiente_proyecto);
        formData.append('url_name', url_name);
        formData.append('fecha_creacion', fecha_creacion);
        formData.append('fecha_termino', fecha_termino);
        formData.append('pro_desc', pro_desc);
        formData.append('pro_obj', pro_obj);
        formData.append('pro_result', pro_result);
        formData.append('tipo', 'Proyecto');
        formData.append('url_video', url_video);
        console.log(tipo)

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/proyectos/post/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            MySwal.fire({
                title: '¡Enviado!',
                text: 'Has añadido un proyecto correctamente.',
                icon: 'success'
            });
        } catch (error) {
            console.error(error);
            MySwal.fire({
                title: 'Oops...',
                text: 'No se pudo añadir un proyecto.',
                icon: 'error'
            });
        }
    }

    useEffect(() => {
        const urlFormateada = nombre_proyecto.toLowerCase().replace(/\s/g, '');
        seturl_name(urlFormateada);
      }, [nombre_proyecto]);

    return (
        <div>
            <NavBarStaff />
            <main class="main">
                <div className="formulario-container">
                    <div className="formulario-3">
                        <form name="formulario" onSubmit={handleSubmit2} enctype="multipart/form-data">
                            <h2>Agregar proyecto</h2>
                            <div class="form-group">
                                <div class="mb-3">
                                    <label for="exampleFormControlTextarea1" class="form-label">Nombre del proyecto</label>
                                    <input onChange={handlenombre_proyectoChange} value={nombre_proyecto} class="form-control" type="text" id="formFile"></input>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Entorno del proyecto</label>
                                <select onChange={hnadleambiente_proyectoChange} value={ambiente_proyecto} className='form-control'>   
                                    <option value="">-- Selecciona el Entorno --</option>
                                    {ambientes.map((ambiente) => (
                                    <option value={ambiente.url}>{ambiente.nombre}</option>
                                    ))}
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Fecha de inicio del proyecto</label>
                                <input onChange={handlefecha_creacionChange} value={fecha_creacion} class="form-control" type="date" id="formFile"></input>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Fecha de cierre del proyecto</label>
                                <input onChange={handlefecha_terminoChange} value={fecha_termino} class="form-control" type="date" id="formFile"></input>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Descripción del proyecto</label>
                                <textarea onChange={handlepro_descChange} value={pro_desc} class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Objetivos del proyecto</label>
                                <textarea onChange={handlepro_objChange} value={pro_obj} class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Resultados esperados</label>
                                <textarea onChange={handlepro_resultChange} value={pro_result} class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                           
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">URL del Video (youtube)</label>
                                <textarea onChange={handleurl_videoChange} value={url_video} class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Icono (formato .png 1647x1230)</label>
                                <input onChange={handleicono_proyecto} class="form-control" type="file" id="formFile"></input>
                                <p className='bottom-text'>Solo imagen de 950X666</p>
                            </div>
                            <div className='botones'>
                                <button type="submit" class="btn btn-lg btn-primary">Subir</button>
                            </div>
                        </form>
                        <div className='botones'>
                            <Link to={'/staff/entorno/Proyectos'}><button type="button" class="btn btn-danger btn-lg">Volver</button></Link>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
export default Agregarproyectos