import { Routes, Route } from "react-router-dom"
import Inicio from "./Inicio"
import Ambientes from "./pages/Ambientes"
import StaffIndex from "./pages/views/staff/indexstaff";
import LandingIzquierdo from "./pages/views/staff/landingizquierdo";
import LandingDerecho from "./pages/views/staff/landingderecho";
import LandingInferior from "./pages/views/staff/landinginferior"; 
import LandingCarrusel from "./pages/views/staff/landingcarrusel";
import Agregarentorno from "./pages/views/staff/agregarentorno";
import Editarentorno from "./pages/views/staff/editarentorno";
import Editarcontenido from "./pages/views/staff/editarcontenido";
import DetalleiVaras from "./pages/DetalleiVaras";
import Vistaproyecto from "./pages/vistaproyecto";
import Vistaevento from "./pages/vistaevento";
import Agregareventos from "./pages/views/staff/agregareventos";
import Editareventos from "./pages/views/staff/editareventos";
import Agregarproyectos from "./pages/views/staff/agregarproyectos";
import Editarproyectos from "./pages/views/staff/editarproyectos";
import Eventos from "./pages/views/staff/eventos";
import Proyectos from "./pages/views/staff/proyectos";
import EditarProyectos2 from "./pages/views/staff/editarProyectos2";
import Inicio2 from "./Inicio2";
import Redes from "./pages/views/staff/redes";
import Noticias from "./pages/views/staff/noticias";

function Aplicacion() {
  return (
    <div className="Aplicacion">
      <Routes>
        <Route path="/" element={ <Ambientes /> } />
        <Route path="/staff/index" element={<StaffIndex />} />
        <Route path="/staff/landing/panel/izquierdo" element={<LandingIzquierdo />} />
        <Route path="/staff/landing/panel/derecho" element={<LandingDerecho />} />
        <Route path="/staff/landing/panel/inferior" element={<LandingInferior />} />
        <Route path="/staff/entornos" element={<LandingCarrusel />} />
        <Route path="/staff/agregarentorno" element={<Agregarentorno />} />
        <Route path="/staff/editarentorno/:url_entorno" element={<Editarentorno />} />
        <Route path="/staff/agregareventos" element={<Agregareventos />} />
        <Route path="/staff/editareventos" element={<Editareventos />} />
        <Route path="/staff/agregarproyectos" element={<Agregarproyectos />} />
        <Route path="/staff/editarproyecto" element={<EditarProyectos2 />} />
        <Route path="/staff/entorno/Eventos" element={<Eventos />} />
        <Route path="/staff/entorno/Proyectos" element={<Proyectos />} />
        <Route path="/detalle/:url_entorno" element={<DetalleiVaras />} />
        <Route path="/staff/editarcontenido" element={<Editarcontenido />} />
        <Route path="/detalle/:url_entorno/:url_proyecto" element={<Vistaproyecto />} />
        <Route path="/detalle/:url_evento" element={<Vistaevento />} />
        <Route path="/staff/redes" element={<Redes />} />
        <Route path="/staff/noticias" element={<Noticias />} />
        <Route path="/inicio2" element={<Inicio2/>} />
        
        
      </Routes>
    </div>
  )
}

export default Aplicacion


/*<Route path="/login" element={<Login />} />*/ 
/*import Login from "./pages/login"; />*/ 