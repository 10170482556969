import logoivaras from '../../../img/logo/ivaras-logo2.png';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NavBarStaff from '../../../component/navbar-staff';

function IndexStaff(){
    
    return(
        <div>
            <NavBarStaff />
            <main className='main'>
            </main>
        </div>
    );
}
export default IndexStaff;